// @ts-check
// / <reference path="../../../typings/brand.d.ts" />
import { config as defaultConfig } from './default';

/** @type BrandConfig */
export const config = {
  ...defaultConfig,
  auth0: {
    ...defaultConfig.auth0,
    logoutRedirectUrl: '',
  },
  serverUrl: 'https://test-api-dot-bison-233014.appspot.com/',
  serverOutageUrl: 'https://static.relymd.app/test/status.json',
};
