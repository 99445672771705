export default {
  loginHeader: `University Health Virtual Visits give you quick access to local, highly-trained medical providers for unscheduled healthcare needs whether you're at home or on the go.`,
  loginListTitle: `How do University Health Virtual Visits work?`,
  loginList: {
    itemOne: `If it's your first time starting a University Health Virtual Visit consult, you'll need to create an account - this will only take a few minutes.`,
    itemTwo: `Provide a bit of your medical history and the reason for your visit.`,
    itemThree: `Once complete, the provider will review your medical info. The average wait time is less than 10 minutes.`,
    itemFour: `When the provider is ready, you'll be notified that it's time to start your visit.`,
    itemFive: 'Start feeling better, fast!',
    itemSix: '',
  },
};
