export const GenericColors = {
  white: '#FFFFFF',
  base: '#F5F7FA',
  grey: '#EDEFF1',
  grey2: '#D9DDE1',
  grey3: '#B6BEC7',
  grey4: '#7C8C9A',
  grey5: '#5A6672',
  grey6: '#394148',
  grey7: '#2D3436',
  green: '#27AE60',
  blue: '#0078D7',
  red: '#C0392B',
  yellow: '#F2CC35',
  orange: '#E67E22',
  purple: '#9B59B6',
  pink: '#FD79A8',
};
