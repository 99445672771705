// @ts-check
// / <reference path="../../../typings/brand.d.ts" />
import { GenericColors } from '../../styles/colors';

/** @type BrandConfig */
export const config = {
  entranceId: 'ent_uhcs',
  auth0: {
    domain: 'uhcs-patient-test.us.auth0.com',
    clientId: 'ZmyfKYYf7Ock3TUI7p8frrYhv3IlqMyk',
    logoutRedirectUrl: '',
    realm: 'Username-Password-Authentication',
  },
  serverUrl: 'https://dev-api-dot-bison-233014.appspot.com/',
  stripeKey: 'pk_test_xASftwBMb3miJrkFUQXEg9GV00adG7MUyN',
  googlePlacesApiKey: 'AIzaSyC1CWPTIoX6OLfW_YhmAoPj3dmYS-o5de0',
  appName: 'University Health Care System',
  privacyPolicyUrl: 'https://static.relymd.app/legal/uhcs/privacy.html',
  termsUrl: 'https://static.relymd.app/legal/uhcs/eula.html',
  defaultPharmacySearchLatLng: [35.78, 78.64],
  serverOutageUrl: 'https://static.relymd.app/dev/status.json',
  iosAppUrl: 'https://apps.apple.com/us/app/uhcs-virtual-visits/id1551353544',
  androidAppUrl: '',
  theme: {
    primary: {
      main: '#00AAA5',
      dark: GenericColors.grey6,
      error: GenericColors.red,
      success: GenericColors.green,
      warning: GenericColors.orange,
      contrastText: GenericColors.white,
    },
    secondary: {
      main: GenericColors.purple,
    },
  },
};
